angular.module('app.controllers').controller 'ProgramsCtrl', [
  '$scope', '$rootScope', 'APIPrograms', '$timeout', 'UI', 'APIRead', 'smoothScr', '$location', '$cookies'
].append (self, root, APIPrograms, $timeout, UI, APIRead, smoothScr, $location, $cookies) ->

  self.urlaubTracks = [2348, 2336]

  root.$on '$routeUpdate', (event, current) ->  
    self.day = parseInt(self.get('day')) || 1
    self.slot = parseInt(self.get('slot')) || null
    self.track = parseInt(self.get('track')) || null

  self.day = parseInt(self.get('day')) || 1

  self.track        = null
  self.exhibitor    = null
  self.program      = null
  self.view         = true
  self.subject_area = parseInt($cookies.get('p.sa.422') || 0)

  self.toggleView = (scroll = false) ->
    self.view = !self.view
    $timeout () -> smoothScr.scroll('#top-content', 0) if scroll

  self.days = ["2025-04-04", "2025-04-05", "2025-04-06"].map (day) ->
    return
      day_formatted: moment(day).format('D.M.YYYY')
      day_title: moment(day).format('dddd')
  
  self.$watch 'subject_area', (newObj, oldObj) ->
    return unless newObj != oldObj
    $location.path("/programm/#{self.day}") if self.get('track')
    loadTracks(false)
  , true
  
  self.$watch 'day', (newObj, oldObj) ->
    loadTracks()
  , true

  self.$watch 'track', (newObj, oldObj) ->
    return unless newObj
    loadSlots()
  , true

  self.$watch 'slot', (newObj, oldObj) ->
    return unless self.slots
    self.setProgram(self.slots.find((sl) -> sl.id == parseInt(self.get('slot'))) || self.slots[0])
  , true

  loadTracks = (getTrack = true) ->
    self.setTrack(null)
    self.tracksLoad = false
    APIRead.query {entry_type: 'tracks', days: self.day, reverse: false}, (data) ->
      self.tracks = data.filter((track) -> 
        return true if self.subject_area == 3
        return if self.subject_area == 1 then track.id in self.urlaubTracks else track.id not in self.urlaubTracks
      )
      self.tracksLoad = true
      if getTrack
        self.setTrack(parseInt(self.get('track')) || self.tracks[0].id)
      else
        self.setTrack(self.tracks[0].id)
    , (response) ->
      self.tracksLoad = false
      UI.notify false, response.data.error

  loadSlots = () ->
    self.slotsLoad = false
    APIRead.query {entry_type: 'programs', days: self.day, tracks: self.track, full: true, reverse: false}, (data) ->
      self.slots = data
      self.slotsLoad = true
      slot = self.slots.find((sl) -> sl.id == parseInt(self.get('slot'))) || self.slots[0]
      self.setProgram(slot)
    , (response) ->
      self.slotsLoad = false
      UI.notify false, response.data.error

  self.setSubject = (subject) ->
    if self.subject_area == subject || self.subject_area == 3
      self.subject_area = if subject == 0 then 1 else 0
    else
      if self.subject_area != subject
        self.subject_area = 3
      else
        self.subject_area = subject_area

    $cookies.put('p.sa.422', self.subject_area)

  self.getSubject = (subject) ->
    self.subject_area in [subject, 3]


  self.setDay = (index) ->
    self.day = index + 1

  self.getDay = (index) ->
    return self.day == index + 1


  self.setTrack = (id) -> 
    return if self.track == id
    self.track = id

  self.getTrack = (id) ->
    return self.track == id

  self.setProgram = (data) ->
    self.program    = data
    self.view       = true
    self.exhibitor  = self.program.exhibitor_entry_data || null
    $timeout () -> smoothScr.scroll('#top-content', 0)

  self.getProgram = (id) ->
    return self.program.id == id




angular.module('app.controllers').controller 'ProgramsHomeCtrl', [
  '$scope', '$rootScope', 'APIPrograms'
].append (self, root, APIPrograms) ->

  APIPrograms.query {_entry: 'programs_home', with_exhibitor: true}, (data) ->
    self.programs = data.map (day) ->
      day.day_formatted = moment(day.day).format('D.M.YYYY')
      return day
  , (response) ->
    UI.notify false, response.data.error

  return
