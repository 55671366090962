var indexOf = [].indexOf;

angular.module('app.controllers').controller('ProgramsCtrl', ['$scope', '$rootScope', 'APIPrograms', '$timeout', 'UI', 'APIRead', 'smoothScr', '$location', '$cookies'].append(function(self, root, APIPrograms, $timeout, UI, APIRead, smoothScr, $location, $cookies) {
  var loadSlots, loadTracks;
  self.urlaubTracks = [2348, 2336];
  root.$on('$routeUpdate', function(event, current) {
    self.day = parseInt(self.get('day')) || 1;
    self.slot = parseInt(self.get('slot')) || null;
    return self.track = parseInt(self.get('track')) || null;
  });
  self.day = parseInt(self.get('day')) || 1;
  self.track = null;
  self.exhibitor = null;
  self.program = null;
  self.view = false;
  self.subject_area = parseInt($cookies.get('p.sa.422') || 0);
  self.toggleView = function(scroll = false) {
    self.view = !self.view;
    return $timeout(function() {
      if (scroll) {
        return smoothScr.scroll('#top-content', 0);
      }
    });
  };
  self.toggleDirectView = function(view) {
    return self.view = view;
  };
  self.days = ["2025-04-04", "2025-04-05", "2025-04-06"].map(function(day) {
    return {
      day_formatted: moment(day).format('D.M.YYYY'),
      day_title: moment(day).format('dddd')
    };
  });
  self.$watch('subject_area', function(newObj, oldObj) {
    if (newObj === oldObj) {
      return;
    }
    if (self.get('track')) {
      $location.path(`/programm/${self.day}`);
    }
    return loadTracks(false);
  }, true);
  self.$watch('day', function(newObj, oldObj) {
    return loadTracks();
  }, true);
  self.$watch('track', function(newObj, oldObj) {
    if (!newObj) {
      return;
    }
    return loadSlots();
  }, true);
  self.$watch('slot', function(newObj, oldObj) {
    if (!self.slots) {
      return;
    }
    return self.setProgram(self.slots.find(function(sl) {
      return sl.id === parseInt(self.get('slot'));
    }) || self.slots[0]);
  }, true);
  loadTracks = function(getTrack = true) {
    self.setTrack(null);
    self.tracksLoad = false;
    return APIRead.query({
      entry_type: 'tracks',
      days: self.day,
      reverse: false
    }, function(data) {
      self.tracks = data.filter(function(track) {
        var ref, ref1;
        if (self.subject_area === 3) {
          return true;
        }
        if (self.subject_area === 1) {
          return ref = track.id, indexOf.call(self.urlaubTracks, ref) >= 0;
        } else {
          return ref1 = track.id, indexOf.call(self.urlaubTracks, ref1) < 0;
        }
      });
      self.tracksLoad = true;
      if (getTrack) {
        return self.setTrack(parseInt(self.get('track')) || self.tracks[0].id);
      } else {
        return self.setTrack(self.tracks[0].id);
      }
    }, function(response) {
      self.tracksLoad = false;
      return UI.notify(false, response.data.error);
    });
  };
  loadSlots = function() {
    self.slotsLoad = false;
    return APIRead.query({
      entry_type: 'programs',
      days: self.day,
      tracks: self.track,
      full: true,
      reverse: false
    }, function(data) {
      var slot;
      self.slots = data;
      self.slotsLoad = true;
      slot = self.slots.find(function(sl) {
        return sl.id === parseInt(self.get('slot'));
      }) || self.slots[0];
      return self.setProgram(slot, false);
    }, function(response) {
      self.slotsLoad = false;
      return UI.notify(false, response.data.error);
    });
  };
  self.setSubject = function(subject) {
    if (self.subject_area === subject || self.subject_area === 3) {
      self.subject_area = subject === 0 ? 1 : 0;
    } else {
      if (self.subject_area !== subject) {
        self.subject_area = 3;
      } else {
        self.subject_area = subject_area;
      }
    }
    return $cookies.put('p.sa.422', self.subject_area);
  };
  self.getSubject = function(subject) {
    var ref;
    return (ref = self.subject_area) === subject || ref === 3;
  };
  self.setDay = function(index) {
    return self.day = index + 1;
  };
  self.getDay = function(index) {
    return self.day === index + 1;
  };
  self.setTrack = function(id) {
    if (self.track === id) {
      return;
    }
    return self.track = id;
  };
  self.getTrack = function(id) {
    return self.track === id;
  };
  self.setProgram = function(data, view = true) {
    self.program = data;
    self.view = view;
    self.exhibitor = self.program.exhibitor_entry_data || null;
    return $timeout(function() {
      return smoothScr.scroll('#top-content', 0);
    });
  };
  return self.getProgram = function(id) {
    return self.program.id === id;
  };
}));

angular.module('app.controllers').controller('ProgramsHomeCtrl', ['$scope', '$rootScope', 'APIPrograms'].append(function(self, root, APIPrograms) {
  APIPrograms.query({
    _entry: 'programs_home',
    with_exhibitor: true
  }, function(data) {
    return self.programs = data.map(function(day) {
      day.day_formatted = moment(day.day).format('D.M.YYYY');
      return day;
    });
  }, function(response) {
    return UI.notify(false, response.data.error);
  });
}));
